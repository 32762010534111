<script>
import Loader from "vue-element-loading";

export default {
  name: "Manager",
  components: {
    Loader,
  },
  data() {
    return {
      initialised: false,
      logged_in: false,
    };
  },
  computed: {
    isOnMain() {
      return this.$route.name === "Manager-LandingChooser";
    },
    spaces() {
      return this.$store.state.Manager.spaces;
    },
    isManager() {
      return this.$store.state.Auth?.authData?.is_manager;
    },
    loading() {
      return this.$store.getters["General/getLoading"];
    },
  },
  created: async function () {
    const promises = [];
    promises.push(this.$store.dispatch("Manager/getGroups"));
    promises.push(this.$store.dispatch("Manager/getSpaces"));
    promises.push(this.$store.dispatch("Manager/getManager"));
    await Promise.all(promises);
    this.initialised = true;
  },
};
</script>

<template>
  <div>
    <div class="container" v-if="!isManager || !initialised">
      <div class="py-5 text-center">
        <h3>Can only see this page when logged in as a manager.</h3>
      </div>
    </div>
    <div v-else>
      <Loader
        id="loader"
        style="position: fixed"
        :active="loading"
        spinner="ring"
        color="#64B963"
      />
      <div class="container" v-if="isOnMain">
        <div class="row my-3">
          <div class="col">
            <h3>What Do You Want To See</h3>
          </div>
        </div>

        <div class="row">
          <div class="col choices">
            <div class="choice-card">
              <div class="choice-card-inner">
                <router-link :to="{ name: 'Manager-Dashboard' }" class="company"
                  >All Company Settings</router-link
                >
              </div>
            </div>

            <div class="choice-card" v-for="space in spaces" :key="space.id">
              <div class="choice-card-inner">
                <router-link
                  :to="{ name: 'Space', params: { slug: space.slug } }"
                  >'{{ space.name }}' Space</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view v-else />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.choices {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .choice-card {
    font-size: 16px;
    margin: 0 4% 4% 0;
    margin-bottom: 8px;
    margin-right: 8px;
    width: min(calc(40% - 4px), 200px);
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
    position: relative;
    .choice-card-inner {
      background-position: 50%;
      background-size: cover;
      line-height: 20px;
      padding: 8px;
      height: 80px;
      position: relative;
      text-decoration: none;
      a {
        padding: 6px 8px;
        font-weight: bold;
        text-decoration: none;
        &.company {
          background-color: rgb(131, 140, 145);
          color: #fff;
        }
        background-color: #091e4214;
        color: #172b4d;
        border-radius: 3px;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
</style>
